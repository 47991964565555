import { themes, Notes, Head } from "mdx-deck";
import { Layout, Profile, IFrame } from "../../../../../../components";
import Title from "../../../../../../assets/title.png";
import Ast from "../../../../../../assets/ast.png";
import AstExample from "../../../../../../assets/astexample.png";
import UnusedVarDemo from "../../../../../../assets/unusedVar.png";
import Devc from "../../../../../../assets/devc.jpeg";
import Devc2 from "../../../../../../assets/devc2.jpg";
import Kiwalo from "../../../../../../assets/kiwalo.jpg";
import Qs from "../../../../../../assets/qs.jpg";
import Tools from "../../../../../../assets/tools.png";
import Deps from "../../../../../../assets/deps.png";
import Babel from "../../../../../../assets/babel.png";
import Babeljs from "../../../../../../assets/babeljs.png";
import Eslint from "../../../../../../assets/eslint.png";
import Eslintjs from "../../../../../../assets/eslint_work.png";
import Richard from "../../../../../../assets/richard.png";
import React from 'react';
export default {
  themes: themes,
  Notes: Notes,
  Head: Head,
  Layout: Layout,
  Profile: Profile,
  IFrame: IFrame,
  Title: Title,
  Ast: Ast,
  AstExample: AstExample,
  UnusedVarDemo: UnusedVarDemo,
  Devc: Devc,
  Devc2: Devc2,
  Kiwalo: Kiwalo,
  Qs: Qs,
  Tools: Tools,
  Deps: Deps,
  Babel: Babel,
  Babeljs: Babeljs,
  Eslint: Eslint,
  Eslintjs: Eslintjs,
  Richard: Richard,
  React: React
};