module.exports = function (hljs) {
  var SUBST = {
    className: 'subst',
    variants: [{
      begin: '\\${',
      end: '}'
    }, {
      begin: '\\$[A-Za-z0-9_]+'
    }],
    keywords: 'true false null this is new super'
  };
  var STRING = {
    className: 'string',
    variants: [{
      begin: 'r\'\'\'',
      end: '\'\'\''
    }, {
      begin: 'r"""',
      end: '"""'
    }, {
      begin: 'r\'',
      end: '\'',
      illegal: '\\n'
    }, {
      begin: 'r"',
      end: '"',
      illegal: '\\n'
    }, {
      begin: '\'\'\'',
      end: '\'\'\'',
      contains: [hljs.BACKSLASH_ESCAPE, SUBST]
    }, {
      begin: '"""',
      end: '"""',
      contains: [hljs.BACKSLASH_ESCAPE, SUBST]
    }, {
      begin: '\'',
      end: '\'',
      illegal: '\\n',
      contains: [hljs.BACKSLASH_ESCAPE, SUBST]
    }, {
      begin: '"',
      end: '"',
      illegal: '\\n',
      contains: [hljs.BACKSLASH_ESCAPE, SUBST]
    }]
  };
  SUBST.contains = [hljs.C_NUMBER_MODE, STRING];
  var KEYWORDS = {
    keyword: 'assert async await break case catch class const continue default do else enum extends false final ' + 'finally for if in is new null rethrow return super switch sync this throw true try var void while with yield ' + 'abstract as dynamic export external factory get implements import library operator part set static typedef',
    built_in: // dart:core
    'print Comparable DateTime Duration Function Iterable Iterator List Map Match Null Object Pattern RegExp Set ' + 'Stopwatch String StringBuffer StringSink Symbol Type Uri bool double int num ' + // dart:html
    'document window querySelector querySelectorAll Element ElementList'
  };
  return {
    keywords: KEYWORDS,
    contains: [STRING, hljs.COMMENT('/\\*\\*', '\\*/', {
      subLanguage: 'markdown'
    }), hljs.COMMENT('///', '$', {
      subLanguage: 'markdown'
    }), hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE, {
      className: 'class',
      beginKeywords: 'class interface',
      end: '{',
      excludeEnd: true,
      contains: [{
        beginKeywords: 'extends implements'
      }, hljs.UNDERSCORE_TITLE_MODE]
    }, hljs.C_NUMBER_MODE, {
      className: 'meta',
      begin: '@[A-Za-z]+'
    }, {
      begin: '=>' // No markup, just a relevance booster

    }]
  };
};