// example Layout.js
import React from "react";

import Image from "../assets/elazizi.png";

export default () => (
  <img
    style={{
      WebkitFilter: "grayscale(100%)",
      filter: "grayscale(100%)"
    }}
    height="200px"
    width="200px"
    src={Image}
  />
);
