import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
/** @jsx jsx */

import { jsx } from 'theme-ui';
import { useDeck } from 'gatsby-theme-mdx-deck';
import { Link } from 'gatsby';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Provider = function Provider(props) {
  var _useDeck = useDeck(),
      index = _useDeck.index,
      length = _useDeck.length,
      slug = _useDeck.slug;

  var dots = Array.from({
    length: length
  }).map(function (n, i) {
    return i;
  });
  return jsx("div", {
    sx: {
      position: 'relative'
    }
  }, props.children, jsx("div", {
    sx: {
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      p: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    }
  }, dots.map(function (i) {
    return jsx(Link, {
      key: i,
      to: slug + '/' + i,
      title: "Go to slide " + i,
      sx: {
        px: 1,
        py: 2
      }
    }, jsx("div", {
      sx: {
        fontSize: '0px',
        width: 6,
        height: 6,
        borderRadius: 9999,
        bg: 'text'
      },
      style: {
        opacity: i <= index ? 0.75 : 0.25
      }
    }, i));
  })));
};

export default {
  Provider: Provider
};