import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.object.assign";
import React, { forwardRef } from 'react';

var isFunction = function isFunction(obj) {
  return typeof obj === 'function';
};

var MDXContext = React.createContext({});

var withMDXComponents = function withMDXComponents(Component) {
  return function (props) {
    var allComponents = useMDXComponents(props.components);
    return React.createElement(Component, Object.assign({}, props, {
      components: allComponents
    }));
  };
};

var useMDXComponents = function useMDXComponents(components) {
  var contextComponents = React.useContext(MDXContext);
  var allComponents = contextComponents;

  if (components) {
    allComponents = isFunction(components) ? components(contextComponents) : Object.assign({}, contextComponents, components);
  }

  return allComponents;
};

var MDXProvider = function MDXProvider(props) {
  var allComponents = useMDXComponents(props.components);
  return React.createElement(MDXContext.Provider, {
    value: allComponents
  }, props.children);
};

function objectWithoutProperties(obj, exclude) {
  var target = {};

  for (var k in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k];
  }

  return target;
}

var TYPE_PROP_NAME = 'mdxType';
var DEFAULTS = {
  inlineCode: 'code',
  wrapper: function wrapper(ref) {
    var children = ref.children;
    return React.createElement(React.Fragment, {}, children);
  }
};
var MDXCreateElement = forwardRef(function (props, ref) {
  var propComponents = props.components;
  var mdxType = props.mdxType;
  var originalType = props.originalType;
  var parentName = props.parentName;
  var rest = objectWithoutProperties(props, ["components", "mdxType", "originalType", "parentName"]);
  var etc = rest;
  var components = useMDXComponents(propComponents);
  var type = mdxType;
  var Component = components[parentName + "." + type] || components[type] || DEFAULTS[type] || originalType;

  if (propComponents) {
    return React.createElement(Component, Object.assign({}, {
      ref: ref
    }, etc, {
      components: propComponents
    }));
  }

  return React.createElement(Component, Object.assign({}, {
    ref: ref
  }, etc));
});
MDXCreateElement.displayName = 'MDXCreateElement';

function createElement(type, props) {
  var args = arguments;
  var mdxType = props && props.mdxType;

  if (typeof type === 'string' || mdxType) {
    var argsLength = args.length;
    var createElementArgArray = new Array(argsLength);
    createElementArgArray[0] = MDXCreateElement;
    var newProps = {};

    for (var key in props) {
      if (hasOwnProperty.call(props, key)) {
        newProps[key] = props[key];
      }
    }

    newProps.originalType = type;
    newProps[TYPE_PROP_NAME] = typeof type === 'string' ? type : mdxType;
    createElementArgArray[1] = newProps;

    for (var i = 2; i < argsLength; i++) {
      createElementArgArray[i] = args[i];
    }

    return React.createElement.apply(null, createElementArgArray);
  }

  return React.createElement.apply(null, args);
}

export { MDXContext, MDXProvider, useMDXComponents, withMDXComponents, createElement as mdx };