module.exports = function (hljs) {
  var KEYWORDS = {
    keyword: 'actor addressof and as be break class compile_error compile_intrinsic' + 'consume continue delegate digestof do else elseif embed end error' + 'for fun if ifdef in interface is isnt lambda let match new not object' + 'or primitive recover repeat return struct then trait try type until ' + 'use var where while with xor',
    meta: 'iso val tag trn box ref',
    literal: 'this false true'
  };
  var TRIPLE_QUOTE_STRING_MODE = {
    className: 'string',
    begin: '"""',
    end: '"""',
    relevance: 10
  };
  var QUOTE_STRING_MODE = {
    className: 'string',
    begin: '"',
    end: '"',
    contains: [hljs.BACKSLASH_ESCAPE]
  };
  var SINGLE_QUOTE_CHAR_MODE = {
    className: 'string',
    begin: '\'',
    end: '\'',
    contains: [hljs.BACKSLASH_ESCAPE],
    relevance: 0
  };
  var TYPE_NAME = {
    className: 'type',
    begin: '\\b_?[A-Z][\\w]*',
    relevance: 0
  };
  var PRIMED_NAME = {
    begin: hljs.IDENT_RE + '\'',
    relevance: 0
  };
  var CLASS = {
    className: 'class',
    beginKeywords: 'class actor',
    end: '$',
    contains: [hljs.TITLE_MODE, hljs.C_LINE_COMMENT_MODE]
  };
  var FUNCTION = {
    className: 'function',
    beginKeywords: 'new fun',
    end: '=>',
    contains: [hljs.TITLE_MODE, {
      begin: /\(/,
      end: /\)/,
      contains: [TYPE_NAME, PRIMED_NAME, hljs.C_NUMBER_MODE, hljs.C_BLOCK_COMMENT_MODE]
    }, {
      begin: /:/,
      endsWithParent: true,
      contains: [TYPE_NAME]
    }, hljs.C_LINE_COMMENT_MODE]
  };
  return {
    keywords: KEYWORDS,
    contains: [CLASS, FUNCTION, TYPE_NAME, TRIPLE_QUOTE_STRING_MODE, QUOTE_STRING_MODE, SINGLE_QUOTE_CHAR_MODE, PRIMED_NAME, hljs.C_NUMBER_MODE, hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE]
  };
};