module.exports = function (hljs) {
  var KEYWORDS = {
    keyword: 'and elif is global as in if from raise for except finally print import pass return ' + 'exec else break not with class assert yield try while continue del or def lambda ' + 'async await nonlocal|10 None True False',
    built_in: 'Ellipsis NotImplemented'
  };
  var PROMPT = {
    className: 'meta',
    begin: /^(>>>|\.\.\.) /
  };
  var SUBST = {
    className: 'subst',
    begin: /\{/,
    end: /\}/,
    keywords: KEYWORDS,
    illegal: /#/
  };
  var STRING = {
    className: 'string',
    contains: [hljs.BACKSLASH_ESCAPE],
    variants: [{
      begin: /(u|b)?r?'''/,
      end: /'''/,
      contains: [hljs.BACKSLASH_ESCAPE, PROMPT],
      relevance: 10
    }, {
      begin: /(u|b)?r?"""/,
      end: /"""/,
      contains: [hljs.BACKSLASH_ESCAPE, PROMPT],
      relevance: 10
    }, {
      begin: /(fr|rf|f)'''/,
      end: /'''/,
      contains: [hljs.BACKSLASH_ESCAPE, PROMPT, SUBST]
    }, {
      begin: /(fr|rf|f)"""/,
      end: /"""/,
      contains: [hljs.BACKSLASH_ESCAPE, PROMPT, SUBST]
    }, {
      begin: /(u|r|ur)'/,
      end: /'/,
      relevance: 10
    }, {
      begin: /(u|r|ur)"/,
      end: /"/,
      relevance: 10
    }, {
      begin: /(b|br)'/,
      end: /'/
    }, {
      begin: /(b|br)"/,
      end: /"/
    }, {
      begin: /(fr|rf|f)'/,
      end: /'/,
      contains: [hljs.BACKSLASH_ESCAPE, SUBST]
    }, {
      begin: /(fr|rf|f)"/,
      end: /"/,
      contains: [hljs.BACKSLASH_ESCAPE, SUBST]
    }, hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE]
  };
  var NUMBER = {
    className: 'number',
    relevance: 0,
    variants: [{
      begin: hljs.BINARY_NUMBER_RE + '[lLjJ]?'
    }, {
      begin: '\\b(0o[0-7]+)[lLjJ]?'
    }, {
      begin: hljs.C_NUMBER_RE + '[lLjJ]?'
    }]
  };
  var PARAMS = {
    className: 'params',
    begin: /\(/,
    end: /\)/,
    contains: ['self', PROMPT, NUMBER, STRING]
  };
  SUBST.contains = [STRING, NUMBER, PROMPT];
  return {
    aliases: ['py', 'gyp'],
    keywords: KEYWORDS,
    illegal: /(<\/|->|\?)|=>/,
    contains: [PROMPT, NUMBER, STRING, hljs.HASH_COMMENT_MODE, {
      variants: [{
        className: 'function',
        beginKeywords: 'def'
      }, {
        className: 'class',
        beginKeywords: 'class'
      }],
      end: /:/,
      illegal: /[${=;\n,]/,
      contains: [hljs.UNDERSCORE_TITLE_MODE, PARAMS, {
        begin: /->/,
        endsWithParent: true,
        keywords: 'None'
      }]
    }, {
      className: 'meta',
      begin: /^[\t ]*@/,
      end: /$/
    }, {
      begin: /\b(print|exec)\(/ // don’t highlight keywords-turned-functions in Python 3

    }]
  };
};