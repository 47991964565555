import React from "react";

export default ({ url }) => {
  return (
    <iframe
      style={{
        width: "100vw",
        height: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }}
      src={url}
      sty
    ></iframe>
  );
};
