module.exports = // Base deafult colors in PB IDE: background: #FFFFDF; foreground: #000000;
function (hljs) {
  var STRINGS = {
    // PB IDE color: #0080FF (Azure Radiance)
    className: 'string',
    begin: '(~)?"',
    end: '"',
    illegal: '\\n'
  };
  var CONSTANTS = {
    // PB IDE color: #924B72 (Cannon Pink)
    //  "#" + a letter or underscore + letters, digits or underscores + (optional) "$"
    className: 'symbol',
    begin: '#[a-zA-Z_]\\w*\\$?'
  };
  return {
    aliases: ['pb', 'pbi'],
    keywords: // PB IDE color: #006666 (Blue Stone) + Bold
    // The following keywords list was taken and adapted from GuShH's PureBasic language file for GeSHi...
    'And As Break CallDebugger Case CompilerCase CompilerDefault CompilerElse CompilerEndIf CompilerEndSelect ' + 'CompilerError CompilerIf CompilerSelect Continue Data DataSection EndDataSection Debug DebugLevel ' + 'Default Define Dim DisableASM DisableDebugger DisableExplicit Else ElseIf EnableASM ' + 'EnableDebugger EnableExplicit End EndEnumeration EndIf EndImport EndInterface EndMacro EndProcedure ' + 'EndSelect EndStructure EndStructureUnion EndWith Enumeration Extends FakeReturn For Next ForEach ' + 'ForEver Global Gosub Goto If Import ImportC IncludeBinary IncludeFile IncludePath Interface Macro ' + 'NewList Not Or ProcedureReturn Protected Prototype ' + 'PrototypeC Read ReDim Repeat Until Restore Return Select Shared Static Step Structure StructureUnion ' + 'Swap To Wend While With XIncludeFile XOr ' + 'Procedure ProcedureC ProcedureCDLL ProcedureDLL Declare DeclareC DeclareCDLL DeclareDLL',
    contains: [// COMMENTS | PB IDE color: #00AAAA (Persian Green)
    hljs.COMMENT(';', '$', {
      relevance: 0
    }), {
      // PROCEDURES DEFINITIONS
      className: 'function',
      begin: '\\b(Procedure|Declare)(C|CDLL|DLL)?\\b',
      end: '\\(',
      excludeEnd: true,
      returnBegin: true,
      contains: [{
        // PROCEDURE KEYWORDS | PB IDE color: #006666 (Blue Stone) + Bold
        className: 'keyword',
        begin: '(Procedure|Declare)(C|CDLL|DLL)?',
        excludeEnd: true
      }, {
        // PROCEDURE RETURN TYPE SETTING | PB IDE color: #000000 (Black)
        className: 'type',
        begin: '\\.\\w*' // end: ' ',

      }, hljs.UNDERSCORE_TITLE_MODE // PROCEDURE NAME | PB IDE color: #006666 (Blue Stone)
      ]
    }, STRINGS, CONSTANTS]
  };
};