// example Layout.js
import React from "react";

import Image from "../assets/devoxx.png";

export default ({ children }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "100px"
      }}
    >
      {/* <img
        height="60px"
        style={{ position: "absolute", left: 40, bottom: 20 }}
        src={Image}
      /> */}
      <a
        style={{
          position: "absolute",
          fontSize: 20,
          right: 40,
          bottom: 20,
          textDecoration: "none",
          color: "#FFF"
        }}
        target="_blank"
        href="https://twitter.com/ElaziziYoussouf"
      >
        @ElaziziYoussouf
      </a>
      <span
        onClick={() => document.body.requestFullscreen()}
        style={{
          position: "absolute",
          height: 100,
          width: 100,

          fontSize: 22,
          right: 10,
          top: 10
        }}
      ></span>
      {children}
    </div>
  );
};
